<template>
    <div>
        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-12 text-right">
                        <b-button v-b-modal.settings-modal size="sm" variant="outline-dark" class="mr-1">
                            <b-icon-gear-fill/>
                            Настройки
                        </b-button>
                        <b-button
                            @click="toggleToDishes = !toggleToDishes"
                            size="sm"
                            variant="outline-info"
                            class="mr-1"
                        >
                            {{ toggleToDishes === true ? 'Свернуть до блюд' : 'Развернуть до операций' }}

                            <b-icon-arrow-up-short v-if="toggleToDishes"/>
                            <b-icon-arrow-down-short v-else/>
                        </b-button>
                        <b-dropdown size="sm" variant="outline-dark" text="План" class="mr-1">
                            <b-dropdown-item :to="{name: 'ProductionPlanCreate'}">Новый</b-dropdown-item>
                            <b-dropdown-item @click="updatePlan">Обновить</b-dropdown-item>
                        </b-dropdown>
                        <b-button size="sm" variant="primary" class="mr-1" @click="load">
                            <b-icon-arrow-repeat/>
                        </b-button>
                        <b-button  size="sm" v-b-toggle.filters variant="light"><b-icon-filter/>Фильтры</b-button>
                    </div>
                </div>
                <b-collapse id="filters" visible class="mt-2 text-center">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <b-form-datepicker
                                    v-model="filters.date_from"
                                    size="sm"
                                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                    :start-weekday="1"
                                    locale="ru"
                                    placeholder="Дата от"
                                />
                                <small class="form-text text-muted">Дата от</small>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <b-form-datepicker
                                    v-model="filters.date_to"
                                    size="sm"
                                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                    :start-weekday="1"
                                    locale="ru"
                                    placeholder="Дата до"
                                />
                                <small class="form-text text-muted">Дата до</small>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <b-form-input
                                    v-model="filters.q"
                                    type="search"
                                    size="sm"
                                    debounce="500"
                                    autocomplete="off"
                                />
                                <small class="form-text text-muted">Поиск по номенклатуре</small>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <area-select v-model="filters.area_id" class="form-control-sm"></area-select>
                                <small class="form-text text-muted">Выберите площадку</small>
                            </div>
                        </div>
                    </div>
                </b-collapse>
            </div>

            <div class="card-body">
                <gantt-chart
                    style="height: calc(100vh - 295px)"
                    :tasks="tasks"
                    :links="links"
                    :config="config"
                    :scales="scales"
                    :columns="columns"
                    :start-date="startDate"
                    :end-date="endDate"
                    :toggle-to-subprojects="toggleToDishes"
                    :filter="filters.q"
                    @task-dblclicked="showTaskDetails"
                    @task-resized="updateTask"
                    @task-contextmenu="showContextMenu"
                    @task-changed="updateTask"
                />
            </div>
        </div>

        <task-edit-modal v-model="selectedTask" @ok="updateTask" id="task-edit-modal"/>

        <settings-modal v-model="taskSettings" @ok="setTaskSettings" id="settings-modal"/>

        <fit-into-shift-modal @ok="setActualShift" id="fit-into-shift-modal"/>

        <v-menu v-model="contextmenu.show" :position-x="contextmenu.x" :position-y="contextmenu.y">
            <b-dropdown-item v-b-modal.fit-into-shift-modal>Уместить в смену</b-dropdown-item>
        </v-menu>
    </div>
</template>

<script>
import GanttChart                  from './components/GanttChart';
import TaskEditModal               from './components/TaskEditModal';
import VMenu                       from './components/VMenu';
import FitIntoShiftModal           from './components/FitIntoShiftModal';
import SettingsModal               from './components/SettingsModal';
import {AreaSelect}                from '@components';
import moment                      from 'moment';
import {PRODUCTION_TASKS_ENDPOINT} from '@utils/endpoints';
import {ProductionTaskService}     from '@services';

const OPERATION_TASK_TYPE = 'operation';
const DISH_TASK_TYPE = 'dish';

export default {
    name: 'Index',
    components: {
        GanttChart,
        TaskEditModal,
        SettingsModal,
        VMenu,
        FitIntoShiftModal,
        AreaSelect,
    },
    data() {
        return {
            filters: {
                date_from: moment().format('YYYY-MM-DD'),
                date_to: moment().format('YYYY-MM-DD'),
                area_id: null,
                q: null,
            },
            tasks: [],
            links: [],
            config: {
                drag_progress: false,
                drag_links: false,
                duration_unit: 'minute',
                duration_step: 1,
                date_format: "%Y-%m-%d %H:%i:%s",
                open_tree_initially: true,
                min_duration: 60,
                bar_height: 13,
                row_height: 18,
                details_on_dblclick: false,
                round_dnd_dates: false,
                time_step: 1,
                min_column_width: 100
            },
            scales: [
                {
                    unit: 'day',
                    step: 1,
                    format: '%d.%m.%y'
                },
                {
                    unit: 'minute',
                    step: 60,
                    format: '%H:%i'
                },
            ],
            columns: [
                {
                    name: 'text',
                    label: 'Описание',
                    width: '*',
                    tree: true,
                },
                {
                    name: 'duration',
                    label: '<span title="Продолжительность">П</span>',
                    align: 'center',
                    width: 60,
                    template: (row) => {
                        return moment().startOf('day')
                            .add(row.duration, 'minutes')
                            .format('HH:mm');
                    },
                },
                {
                    name: 'production_count',
                    label: '<span title="Количество">К</span>',
                    align: 'center',
                    width: 40,
                },
                {
                    name: 'available_for_overflow_count',
                    label: '<span title="Доступно к переливу">Д</span>',
                    align: 'center',
                    width: 40,
                },
            ],
            selectedTask: {
                id: null,
                name: null,
                nomenclature_name: null,
                description: null,
                duration: null,
                started_at: null,
                ended_at: null,
                change_start: true,
            },
            startDate: null,
            endDate: null,
            contextmenuShow: false,
            contextmenu: {
                show: false,
                x: 0,
                y: 0,
            },
            toggleToDishes: true,
            popover: {
                show: false,
                x: 0,
                y: 0,
            },
            localStorageKey: 'gantt_chart_settings',
        }
    },
    computed: {
        taskSettings() {
            return this.getTaskSettings();
        },
    },
    methods: {
        showTaskDetails(task) {
            this.selectedTask = Object.assign({}, this.selectedTask, task);
            this.$bvModal.show('task-edit-modal');
        },
        setTaskSettings(settings) {
            window.localStorage.setItem(this.localStorageKey, JSON.stringify(settings));
        },
        getTaskSettings() {
            const settings = window.localStorage.getItem(this.localStorageKey);
            if (settings) {
                return JSON.parse(settings);
            }

            return {
                can_move: false,
                can_move_started: false,
                can_move_left_tasks: true,
                can_move_right_tasks: false,
                can_overlap_deadline: false,
                fixed_intervals: false,
                update_forcibly: false,
            };
        },
        showContextMenu({value, event}) {
            this.contextmenu.show = false;
            if (value.type === DISH_TASK_TYPE) {
                this.contextmenu.x = event.x;
                this.contextmenu.y = event.y;
                this.contextmenu.show = true;
                this.selectedTask = value;
            }
        },
        showPopover({value, event}) {
            this.popover.show = false;
            if (value !== null) {
                this.selectedTask = value;
                this.popover.x = event.x;
                this.popover.y = event.y;
                this.popover.show = true;
            }
        },
        load() {
            const filters = {
                date_from: this.filters.date_from,
                date_to: this.filters.date_to,
            };

            if (this.filters.area_id) {
                filters.area_id = this.filters.area_id;
            }

            ProductionTaskService.getGanttData({filters}).then(({data, startedAt, endedAt}) => {
                this.tasks = [];
                this.links = [];

                data.forEach((task) => {
                    this.tasks.push({
                        id: task.id,
                        text: task.title,
                        start_date: task.start,
                        duration: moment.duration(task.durationValue).asMinutes(),
                        progress: task.percentComplete,
                        parent: task.parentId,
                        color: task.backgroundColor ?? '#17a2b8',
                        textColor: ['#f9f900'].includes(task.backgroundColor) ? '#000' : '#fff',
                        production_count: task.productionCount,
                        available_for_overflow_count: task.alternatableCount,
                        drag_resize: !!task.popoverDescription,
                        unscheduled: !task.parentId,
                        tooltip: `
                                <h5 class="text-wrap font-weight-bold text-justify" style="width: 25rem;">
                                    ${task.popoverDescription ?? task.title}
                                </h5>
                                <h6 class="text-wrap" style="width: 25rem;">
                                    ${task.popoverDescription ? task.title : ''}
                                </h6>
                                <h6 class="text-wrap" style="width: 25rem;">
                                    ${moment(task.start).format('HH:MM')} - ${moment(task.end).format('HH:MM')}
                                    (${moment(task.duration).format('HH:MM')})
                                </h6>
                                <h6 class="text-wrap text-justify" style="width: 25rem;">
                                    ${task.popoverDetails ?? ''}
                                </h6>
                            `,
                        value: {
                            id: task.databaseId,
                            type: task.popoverDescription ? OPERATION_TASK_TYPE : DISH_TASK_TYPE,
                            name: task.title,
                            nomenclature_name: task.popoverDescription ?? null,
                            description: task.popoverDetails ?? null,
                            duration: task.durationValue,
                            started_at: task.start,
                            ended_at: task.end,
                            is_production_plan: !!task.isProductionPlan,
                        }
                    });

                    if (task.successorId) {
                        this.links.push({
                            id: task.id,
                            source: task.id,
                            target: task.successorId,
                            type: 0,
                            color: '#495057',
                        });
                    }
                });

                this.tasks = this.tasks.sort((a, b) => a.value.id - b.value.id);

                this.startDate = startedAt;
                this.endDate = endedAt;
            });
        },
        updateTask(task) {
            const taskSettings = this.getTaskSettings();
            const editedTask = {
                id: task.id,
                isProdPlan: task.is_production_plan,
                start: task.started_at,
                end: task.ended_at,
                moveSettings: {
                    canMove: taskSettings.can_move,
                    canMoveAlreadyStarted: taskSettings.can_move_started,
                    canMoveLeftTasks: taskSettings.can_move_left_tasks,
                    canMoveRightTasks: taskSettings.can_move_right_tasks,
                    canOverlapDeadline: taskSettings.can_overlap_deadline,
                    fixedSpaces: taskSettings.fixed_intervals,
                    updateForcibly: taskSettings.update_forcibly,
                },
                changeStart: task.change_start,
            };

            return this.$http
                .post(PRODUCTION_TASKS_ENDPOINT + '/move', editedTask)
                .then(response => {
                    if (!!response.data.errors && response.data.errors.length) {
                        this.$bvToast.toast(response.data.errors.join("\n"), {
                            variant: 'warning',
                            autoHideDelay: 9000
                        });
                    } else {
                        this.$bvToast.toast('Успешно сохранено', {variant: 'success'});
                    }
                })
                .catch(error => {
                    this.$bvToast.toast(error.data.message, {variant: 'danger', autoHideDelay: 9000});
                }).finally(this.load);
        },
        updatePlan() {
            this.$http
                .get(`${PRODUCTION_TASKS_ENDPOINT}/generate-by-plan`, {
                    params: {
                        dateFrom: moment(this.filters.date_from).format('YYYY-MM-DD HH:mm:ss'),
                        dateTo: moment(this.filters.date_to).format('YYYY-MM-DD HH:mm:ss'),
                    }
                })
                .then(response => {
                    const data = response.data;

                    this.$bvToast.toast(
                        `${data.status} \n добавлено: ${(data.newTasksCount ? data.newTasksCount : 0)}`,
                        {variant: 'success'}
                    );
                })
                .catch(() => {
                    this.$bvToast.toast('Ошибка генерации', {variant: 'danger'})
                });
        },
        setActualShift(value) {
            const params = {
                actual_shift: {
                    id: value.id ?? null,
                },
                production_plan: {
                    id: this.selectedTask.id ?? null,
                }
            };

            this.$http.put(`${PRODUCTION_TASKS_ENDPOINT}/fit-into-shift`, params)
                .then(() => {
                    this.$bvToast.toast('Успешно сохранено', {variant: 'success'});
                    this.load();
                })
                .catch((response) => {
                    this.$bvToast.toast(response.data.error, {variant: 'danger'});
                });
        },
    },
    mounted() {
        this.load();

        document.addEventListener('click', () => {
            this.contextmenu.show = false;
        });
    },
    beforeDestroy() {
        document.removeEventListener('click', () => {
            this.contextmenu.show = false;
        });
    }
}
</script>
