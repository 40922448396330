<template>
    <b-modal v-bind="$attrs" @ok="save" :title="title" ok-variant="success" ok-title="Сохранить" cancel-title="Отмена">
        <h4>{{ task.name }}</h4>
        <b-row>
            <b-col cols="6">
                <b-form-group label="Начало">
                    <b-input-group>
                        <b-form-datepicker
                            v-model="startDate"
                            hide-header
                            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                            :start-weekday="1"
                            locale="ru"
                            placeholder=""
                            size="sm"
                        />
                        <template #append>
                            <input type="time" v-model="startTime" class="form-control">
                        </template>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group label="Конец">
                    <b-input-group>
                        <b-form-datepicker
                            v-model="endDate"
                            hide-header
                            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                            :start-weekday="1"
                            locale="ru"
                            placeholder=""
                            size="sm"
                        />
                        <template #append>
                            <input type="time" v-model="endTime" class="form-control">
                        </template>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="6">
                <b-form-group label="Продолжительность" :description="durationDescription">
                    <input type="time" v-model="task.duration" class="form-control">
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group label="Изменять">
                    <b-form-radio v-model="task.change_start" :value="true">Начало</b-form-radio>
                    <b-form-radio v-model="task.change_start" :value="false">Конец</b-form-radio>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                {{ task.description }}
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
import moment from 'moment';

export default {
    name: 'TaskEditModal',
    props: {
        value: {
            type: Object,
        },
    },
    computed: {
        startDate: {
            get: function () {
                return moment(this.task.started_at).format('YYYY-MM-DD');
            },
            set: function (value) {
                const [hour, minute] = this.startTime.split(':');
                this.task.started_at = moment(value)
                    .set({hour, minute})
                    .format('YYYY-MM-DD HH:mm');
            },
        },
        endDate: {
            get: function () {
                return moment(this.task.ended_at).format('YYYY-MM-DD');
            },
            set: function (value) {
                const [hour, minute] = this.endTime.split(':');
                this.task.ended_at = moment(value)
                    .set({hour, minute})
                    .format('YYYY-MM-DD HH:mm');
            },
        },
        startTime: {
            get: function () {
                return moment(this.task.started_at).format('HH:mm');
            },
            set: function (value) {
                const [hour, minute] = value.split(':');
                this.task.started_at = moment(this.task.started_at)
                    .set({hour, minute})
                    .format('YYYY-MM-DD HH:mm');
            },
        },
        endTime: {
            get: function () {
                return moment(this.task.ended_at).format('HH:mm');
            },
            set: function (value) {
                const [hour, minute] = value.split(':');
                this.task.ended_at = moment(this.task.ended_at)
                    .set({hour, minute})
                    .format('YYYY-MM-DD HH:mm');
            },
        },
        durationDescription() {
            return `Будет изменяться ${this.task.change_start ? 'начало' : 'конец'} задания`;
        },
        title() {
            return this.task.nomenclature_name ?? '';
        }
    },
    data() {
        return {
            task: {
                name: null,
                description: null,
                nomenclature_name: null,
                duration: null,
                started_at: null,
                ended_at: null,
                change_start: true,
            },
        }
    },
    watch: {
        value: {
            handler: function (value) {
                this.task = Object.assign({}, this.task, value);
            },
        }
    },
    methods: {
        save() {
            this.$emit('ok', this.task);
        }
    }
}
</script>

<style scoped>
input[type="time"]::-webkit-calendar-picker-indicator{
    background-image: url("data:image/svg+xml,%3Csvg data-v-41be6633='' viewBox='0 0 16 16' width='1em' height='1em' focusable='false' role='img' aria-label='clock' xmlns='http://www.w3.org/2000/svg' fill='currentColor' class='bi-clock mx-auto b-icon bi'%3E%3Cg data-v-41be6633=''%3E%3Cpath d='M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z'%3E%3C/path%3E%3Cpath d='M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
}
</style>
