<template>
    <b-modal
        v-bind="$attrs"
        title="Уместить план в смену"
        ok-title="Сохранить"
        ok-variant="success"
        cancel-title="Отмена"
        @ok="ok"
    >
        <actual-shift-picker v-model="actualShift"/>
    </b-modal>
</template>

<script>
import ActualShiftPicker from '@components/_common/ActualShiftPicker';

export default {
    name: 'FitIntoShiftModal',
    components: {
        ActualShiftPicker,
    },
    data() {
        return {
            actualShift: {},
        }
    },
    methods: {
        ok() {
            this.$emit('ok', this.actualShift);
        }
    }
}
</script>
