<template>
    <div ref="gantt"/>
</template>

<script>
import {gantt} from 'dhtmlx-gantt';
import moment  from 'moment';

const PRODUCTION_NIGHT_SHIFT = {
    start_hour: 20,
    end_hour: 8,
};

const PROJECT_LEVEL = 0;
const SUBPROJECT_LEVEL = 1;
const TASK_LEVEL = 2;

export default {
    name: 'GanttChart',
    props: {
        tasks: {
            type: Array,
            default: () => [],
            required: true,
        },
        links: {
            type: Array,
            default: () => [],
            required: true,
        },
        config: {
            type: Object,
            default: () => {},
            required: false,
        },
        scales: {
            type: Array,
            default: () => [],
            required: false,
        },
        columns: {
            type: Array,
            default: () => [],
            required: false,
        },
        startDate: {
            type: String,
            required: false,
        },
        endDate: {
            type: String,
            required: false,
        },
        toggleToSubprojects: {
            type: Boolean,
            required: false,
            default: false,
        },
        filter: {
            type: String,
            required: false,
            default: () => null,
        },
    },
    data() {
        return {
            gantt,
            scrollX: 0,
            scrollY: 0,
            treeToggleLevel: TASK_LEVEL,
            taskDragHighlightElement: null,
            taskDragStartDateElement: null,
            taskDragEndDateElement: null,
        }
    },
    watch: {
        tasks: {
            handler: function () {
                this.gantt.clearAll();
                if (this.tasks.length && this.links.length) {
                    this.gantt.parse({
                        data: this.tasks,
                        links: this.links,
                    });

                    this.gantt.scrollTo(this.scrollX, this.scrollY);

                    this.treeToggleToLevel(this.treeToggleLevel);
                }
            },
        },
        startDate: {
            handler: function (value) {
                this.gantt.config.start_date = new Date(value);
                this.gantt.render();
            },
        },
        endDate: {
            handler: function (value) {
                this.gantt.config.end_date = new Date(value);
                this.gantt.render();
            },
        },
        toggleToSubprojects: {
            handler: function () {
                this.treeToggleToLevel(SUBPROJECT_LEVEL);
            },
        },
        filter: {
            handler: function () {
                this.gantt.render();
            }
        }
    },
    methods: {
        init() {
            this.gantt.config = Object.assign({}, this.gantt.config, this.config);
            this.gantt.config.scales = this.scales;
            this.gantt.config.columns = this.columns;


            // Визуализация ночной смены
            this.gantt.templates.timeline_cell_class = (task, date) => {
                if (date.getHours() >= PRODUCTION_NIGHT_SHIFT.start_hour
                    || date.getHours() < PRODUCTION_NIGHT_SHIFT.end_hour
                ) {
                    return 'night-shift';
                }
            };

            this.gantt.plugins({
                tooltip: true,
            });

            this.gantt.templates.tooltip_text = (start, end, task) => {
                if (task.tooltip) {
                    return task.tooltip;
                }
            };

            this.gantt.templates.grid_row_class = (start, end, task) => {
                if (task.$level === SUBPROJECT_LEVEL) {
                    return 'font-weight-bold';
                }

                return '';
            };

            this.gantt.templates.link_class = (link) => {
                if (link.is_selected) {
                    link.is_selected = false;

                    return 'selected-link';
                }
            };

            this.gantt.templates.task_class = () => {
                return 'z-index-800';
            };

            this.gantt.init(this.$refs.gantt);
        },
        initEvents() {
            const modes = this.gantt.config.drag_mode;

            this.gantt.attachEvent('onGanttRender', () => {
                // Добавляем слой для визуализации перетаскивания/изменения размера задания
                if (!this.taskDragHighlightElement) {
                    this.taskDragHighlightElement = document.createElement('div');
                    this.taskDragHighlightElement.className = 'task-drag-highlight';

                    document.querySelector('.gantt_data_area')
                        .appendChild(this.taskDragHighlightElement);
                }

                // Добавляем слой для визуализации дат при перетаскивании/изменения размера задания
                if (!this.taskDragStartDateElement) {
                    this.taskDragStartDateElement = document.createElement('div');

                    this.taskDragStartDateElement.style.position = 'absolute';

                    document.querySelector('.gantt_data_area')
                        .appendChild(this.taskDragStartDateElement);
                }

                if (!this.taskDragEndDateElement) {
                    this.taskDragEndDateElement = document.createElement('div');

                    this.taskDragEndDateElement.style.position = 'absolute';

                    document.querySelector('.gantt_data_area')
                        .appendChild(this.taskDragEndDateElement);
                }

                return true;
            }, {});

            this.gantt.attachEvent('onTaskDblClick', (id) => {
                const task = this.gantt.getTask(id);

                this.$emit('task-dblclicked', {
                    ...task.value,
                });
                this.scrollX = this.gantt.getScrollState().x;
                this.scrollY = this.gantt.getScrollState().y;

                return false;
            }, {});

            this.gantt.attachEvent('onLinkDblClick', () => false, {});

            this.gantt.attachEvent('onTaskDrag', (id) => {
                const task = this.gantt.getTask(id);

                const sizes = this.gantt.getTaskPosition(task, task.start_date, task.end_date);

                this.taskDragHighlightElement.style.left = `${sizes.left}px`;
                this.taskDragHighlightElement.style.top = '0px';
                this.taskDragHighlightElement.style.width = `${sizes.width}px`;
                this.taskDragHighlightElement.style.height = `${this.gantt.getVisibleTaskCount() * this.gantt.config.row_height}px`;
                this.taskDragHighlightElement.style.lineHeight = `${this.gantt.getVisibleTaskCount() * this.gantt.config.row_height}px`;

                this.taskDragStartDateElement.style.left = `${sizes.left - this.taskDragStartDateElement.getBoundingClientRect().width - 20}px`;
                this.taskDragStartDateElement.style.top = `${sizes.top + sizes.rowHeight / 4 - 5}px`;

                this.taskDragStartDateElement.innerHTML = `<h5>${moment(task.start_date).format('DD.MM.YYYY HH:mm:ss')}</h5>`;

                this.taskDragEndDateElement.style.left = `${sizes.left + sizes.width + 20}px`;
                this.taskDragEndDateElement.style.top = `${sizes.top + sizes.rowHeight / 4 - 5}px`;

                this.taskDragEndDateElement.innerHTML = `<h5>${moment(task.end_date).format('DD.MM.YYYY HH:mm:ss')}</h5>`;

                return true;
            }, {});

            this.gantt.attachEvent('onBeforeTaskDrag', (id, mode) => {
                const task = this.gantt.getTask(id);
                if (!task.drag_resize && mode === modes.resize) {
                    return false;
                }

                this.taskDragHighlightElement.style.display = 'block';
                this.taskDragStartDateElement.style.display = 'block';
                this.taskDragEndDateElement.style.display = 'block';

                return true;
            }, {});

            this.gantt.attachEvent('onAfterTaskDrag', (id) => {
                this.taskDragHighlightElement.style.display = 'none';
                this.taskDragStartDateElement.style.display = 'none';
                this.taskDragEndDateElement.style.display = 'none';

                const task = this.gantt.getTask(id);

                this.scrollX = this.gantt.getScrollState().x;
                this.scrollY = this.gantt.getScrollState().y;

                this.$emit('task-changed', {
                    ...task.value,
                    started_at: moment(task.start_date).format('YYYY-MM-DD HH:mm'),
                    ended_at: moment(task.end_date).format('YYYY-MM-DD HH:mm'),
                });

                return false;
            }, {});

            this.gantt.attachEvent('onContextMenu', (taskId, linkId, event) => {
                event.preventDefault();

                if (taskId) {
                    const task = this.gantt.getTask(taskId);

                    this.$emit('task-contextmenu', {value: task.value, event: event});

                    return true;
                }

                return false;
            }, {});

            this.gantt.attachEvent('onBeforeTaskDisplay', (id, task) => {
                if (!this.filter) {
                    return true;
                }

                const checkParentsById = (id) => {
                    let showTask = false;
                    this.gantt.eachParent((task) => {
                        if (~task.text.toLowerCase().indexOf(this.filter.toLowerCase())) {
                            showTask = true;
                        }
                    },
                    id
                    );

                    return showTask;
                }

                if (this.filter
                    && (
                        ~task.text.toLowerCase().indexOf(this.filter.toLowerCase())
                        || checkParentsById(id)
                    )
                ) {
                    return true;
                }

                return false;
            }, {});

            document.body.addEventListener('click', (event) => {
                // Убираем старое выделение
                for (const item of document.querySelectorAll('.selected-link')) {
                    item.classList.remove('selected-link');
                }

                if (event.target.className === 'gantt_task_content') {
                    const task = this.gantt.getTask(event.target.parentNode.dataset.taskId);
                    const linkIds = [...task.$source, ...task.$target];
                    for (const id of linkIds) {
                        const link = this.gantt.getLink(id);
                        link.is_selected = true;
                        this.gantt.refreshLink(link.id);
                    }
                }
            });
        },
        treeToggleToLevel(level) {
            this.gantt.eachTask((task) => {
                if (task.$level === level) {
                    task.$open = !task.$open;
                }
            });

            this.treeToggleLevel = level;
            this.gantt.render();
        },
    },
    mounted() {
        this.init();
        this.initEvents();
    },
}
</script>


<style>
@import '~dhtmlx-gantt/codebase/dhtmlxgantt.css';

.gantt_grid_data .gantt_row.odd:hover,
.gantt_grid_data .gantt_row:hover,
.gantt_grid_data .gantt_row.gantt_selected,
.gantt_grid_data .gantt_row.odd.gantt_selected,
.gantt_task_row.gantt_selected .gantt_task_cell,
.gantt_task_row.gantt_selected {
    background-color: #ced4da !important;
    border-right-color: #ced4da !important;
}

.gantt_tree_icon.gantt_file {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"/></svg>');
}

.gantt_tree_icon.gantt_folder_open {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path d="M1 3.5A1.5 1.5 0 0 1 2.5 2h2.764c.958 0 1.76.56 2.311 1.184C7.985 3.648 8.48 4 9 4h4.5A1.5 1.5 0 0 1 15 5.5v.64c.57.265.94.876.856 1.546l-.64 5.124A2.5 2.5 0 0 1 12.733 15H3.266a2.5 2.5 0 0 1-2.481-2.19l-.64-5.124A1.5 1.5 0 0 1 1 6.14V3.5zM2 6h12v-.5a.5.5 0 0 0-.5-.5H9c-.964 0-1.71-.629-2.174-1.154C6.374 3.334 5.82 3 5.264 3H2.5a.5.5 0 0 0-.5.5V6zm-.367 1a.5.5 0 0 0-.496.562l.64 5.124A1.5 1.5 0 0 0 3.266 14h9.468a1.5 1.5 0 0 0 1.489-1.314l.64-5.124A.5.5 0 0 0 14.367 7H1.633z"/></svg>');
}

.gantt_tree_icon.gantt_folder_closed {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path d="M1 3.5A1.5 1.5 0 0 1 2.5 2h2.764c.958 0 1.76.56 2.311 1.184C7.985 3.648 8.48 4 9 4h4.5A1.5 1.5 0 0 1 15 5.5v7a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 1 12.5v-9zM2.5 3a.5.5 0 0 0-.5.5V6h12v-.5a.5.5 0 0 0-.5-.5H9c-.964 0-1.71-.629-2.174-1.154C6.374 3.334 5.82 3 5.264 3H2.5zM14 7H2v5.5a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5V7z"/></svg>');
}

.gantt_tree_icon.gantt_close {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 16 16"><path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/><path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/></svg>');
}

.gantt_tree_icon.gantt_open {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 16 16"><path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/><path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/></svg>');
}

.task-drag-highlight {
    position: absolute;
    background-color: #28a745;
    opacity: 0.1;
}

.night-shift {
    background-color: #f5f5f5;
}

.selected-link .gantt_line_wrapper div {
    background-color: #fd7e14 !important;
}

.selected-link .gantt_line_wrapper {
    z-index: 700;
}

.selected-link .gantt_link_arrow_right,
.selected-link .gantt_link_arrow_left {
    border-left-color: #fd7e14 !important;
    z-index: 700;
}

.z-index-800 {
    z-index: 800;
}

.gantt_tooltip {
    z-index: 900 !important;
}
</style>
