<template>
    <div
        :class="{show: show}"
        :style="{top: top + 'px', left: left + 'px'}"
        class="dropdown-menu"
    >
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'VMenu',
    props: {
        value: {
            type: Boolean,
            required: true,
            default: false,
        },
        positionX: {
            type: Number,
            required: true,
            default: 0,
        },
        positionY: {
            type: Number,
            required: true,
            default: 0,
        },
    },
    watch: {
        value: function (value) {
            this.show = value;
        },
        positionX: function (value) {
            this.left = value;
        },
        positionY: function (value) {
            this.top = value;
        }
    },
    data() {
        return {
            show: false,
            top: 0,
            left: 0,
        }
    },
    created() {
        document.addEventListener('click', () => {
            this.show = false;
        });
    },
    beforeDestroy() {
        document.removeEventListener('click', () => {
            this.show = false;
        });
    }
}
</script>
