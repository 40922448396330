<template>
    <b-modal v-bind="$attrs" @ok="ok" title="Настройки" centered button-size="sm">
        <b-row>
            <b-col cols="6">
                <b-form-checkbox v-model="settings.can_move_right_tasks">
                    Правые задания сдвигаемы
                </b-form-checkbox>
            </b-col>
            <b-col cols="6">
                <b-form-checkbox v-model="settings.can_move_left_tasks">
                    Левые задания сдвигаемы
                </b-form-checkbox>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="6">
                <b-form-checkbox v-model="settings.can_move_started">
                    Можно двигать начатые
                </b-form-checkbox>
            </b-col>
            <b-col cols="6">
                <b-form-checkbox v-model="settings.fixed_intervals">
                    Фиксированные промежутки
                </b-form-checkbox>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="6">
                <b-form-checkbox v-model="settings.can_overlap_deadline">
                    Можно выходить за дедлайн
                </b-form-checkbox>
            </b-col>
            <b-col cols="6">
                <b-form-checkbox v-model="settings.update_forcibly">
                    Обновлять принудительно
                </b-form-checkbox>
            </b-col>
        </b-row>
        <template #modal-footer="{ ok }">
            <b-row>
                <b-col cols="12" class="text-right">
                    <b-button @click="ok()" size="sm" variant="primary">Сохранить</b-button>
                </b-col>
            </b-row>
        </template>
    </b-modal>
</template>

<script>
export default {
    name: 'SettingsModal',
    props: {
        value: {
            type: Object,
            required: false,
            default: () => {},
        },
    },
    data() {
        return {
            settings: {
                can_move: false,
                can_move_started: false,
                can_move_left_tasks: true,
                can_move_right_tasks: false,
                can_overlap_deadline: false,
                fixed_intervals: false,
                update_forcibly: false,
            },
        }
    },
    watch: {
        value: {
            handler: function (value) {
                this.settings = Object.assign({}, this.settings, value);
            },
        },
    },
    methods: {
        ok() {
            this.$emit('ok', this.settings);
        },
    },
    mounted() {
        this.settings = Object.assign({}, this.settings, this.value);
    }
}
</script>
